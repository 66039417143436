export const complianceMenu = [
  {
    name: "Data Collection",
    path: "/compliance/data-collection",
  },
  {
    name: "Disclaimer",
    path: "/compliance/disclaimer",
  },
]

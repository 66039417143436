// extracted by mini-css-extract-plugin
export var FooterContainer = "Footer-module--FooterContainer--Bz3bc";
export var Navigation = "Footer-module--Navigation--I68Zf";
export var TextLink = "Footer-module--TextLink--1pSCJ";
export var Logo = "Footer-module--Logo--30XFJ";
export var SocialContainer = "Footer-module--SocialContainer--3dDIS";
export var Social = "Footer-module--Social--kHNgx";
export var Legal = "Footer-module--Legal--2gONm";
export var NavigationSub = "Footer-module--NavigationSub--1m4_A";
export var SubWrapper = "Footer-module--SubWrapper--1YqJt";
export var SubTitle = "Footer-module--SubTitle--3sjYp";
export var SubSub = "Footer-module--SubSub--D-jw4";
export var LogoContainer = "Footer-module--LogoContainer--3eFT9";
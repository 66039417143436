export const footerMenuMain = [
  {
    path: "/",
    text: "Home",
    partiallyActive: false,
  },
  {
    path: "/about",
    text: "About Us",
    partiallyActive: false,
  },
  {
    path: "/news",
    text: "News",
    partiallyActive: false,
  },
  {
    path: "/contact",
    text: "Contact Us",
    partiallyActive: false,
  },
]
